import '@babel/polyfill'
import 'mutationobserver-shim'
import Vue from 'vue'
import './plugins/bootstrap-vue'
import App from './App.vue'
import i18n from './i18n';
import router from './router'
import store from './vuex/store'; //  引入vuex实例
import './plugins/element.js'
import VueWechatTitle from 'vue-wechat-title';

Vue.use(VueWechatTitle)
Vue.config.productionTip = false

new Vue({
  router,
  i18n,
  store,  
  render: h => h(App)
}).$mount('#app')


