import Vue from 'vue';
import Vuex from 'vuex';
import * as actions from './actions';
import * as getters from './getters';

Vue.use(Vuex); // 安装 Vuex 插件

// 创建初始应用全局状态变量
const state = {
  locale: "en",
};

// 定义所需的 mutations
const mutations = {
};

// 创建 store 实例并且导出
export default new Vuex.Store({
  actions,
  getters,
  state,
  mutations
});
