import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Binding from '../views/Binding.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    meta: {
      title: 'home'
    },
    component: Home
    
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/binding',
    name: 'Binding',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: Binding
  },
  {
    path:'/receipt',
    name:'Receipt',
    meta: {
      title: 'receipt'
    },
    component:()=>import('../views/Receipt.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  // base: '/',
  routes
})

export default router
