<template>
  <div class="binding">
    <h1>绑定账号</h1>
    <h5 v-if="code">{{ code }}</h5>
    <h5 v-if="state">{{ state }}</h5>
    <div v-if="isBound" style="font-size:20px">
      <i style="color:green;" class="el-icon-success"></i> 绑定成功！
    </div>
    <el-form
      v-else
      :model="ruleForm"
      :label-position="labelPosition"
      ref="ruleForm"
      label-width="100px"
      class="demo-ruleForm"
    >
      <el-form-item label="用户名" prop="username">
        <el-input type="text" v-model="ruleForm.username"></el-input>
      </el-form-item>
      <el-form-item label="密码" prop="pass">
        <el-input type="password" v-model="ruleForm.pass"></el-input>
      </el-form-item>
      <el-button type="primary" @click="submitForm(ruleForm)">提交</el-button>
    </el-form>
  </div>
</template>
<script>
export default {
  created(){
    this.code = this.$route.query.code;
    this.state = this.$route.query.state; 
  },
  data() {
    return {
      labelPosition: "left",
      ruleForm: {
        username: "",
        pass: "",
      },
      isBound: "",
      code: "",
      state: "",
    };
  },
  methods: {
    submitForm(ruleForm) {
      console.log(ruleForm);
      if (ruleForm.username === "admin" && ruleForm.pass === "123") {
        this.isBound = true;
      } else {
        this.$notify.error({
          title: "错误",
          message: "没有这个用户",
        });
      }
      // this.$notify({
      //     title: '成功',
      //     message: '这是一条成功的提示消息',
      //     type: 'success'
      //   });
    },
  },
 
};
</script>
