import Vue from 'vue';
import VueI18n from 'vue-i18n'; // 引入 i18n 国际化组件

Vue.use(VueI18n);

const i18n = new VueI18n({
    locale: 'en',  // 设置默认使用语言
    messages: {
        en: {
            'home':'Home',
            'receipt': 'Receipt'
        },
        zh_cn: {
            'home':'Home',
            'receipt': '单据审核'
        }
    }
});

export default i18n;